import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AddAchPaymentSourceCanModel } from '../../../../../../../../_shared/_models/add-ach-payment-source-can.model';
import { LogSiteActivityService } from '../../../../../../../../_shared/_web-services/log-site-activity.service';
import { BaseApplicationComponent } from "../../../../../../../../_shared/core/basecomponent/base.component";
import { ContractAccountDetailDTO } from '../../../../../../../../_shared/core/gateway-api';
import { MaskService } from '../../../../../../../../_shared/shared/_helper-services/mask.service';
import { RouterService } from '../../../../../../../../_shared/shared/_helper-services/router.service';
import { WindowService } from '../../../../../../../../_shared/shared/_helper-services/window.service';
import { Constants } from '../../../../../../../../_shared/shared/constants';
import { AccountTypes } from '../../../../../../../../_shared/shared/enums';
import { AddChequingAccountActions } from '../../../../../../../../_shared/shared/store/reducers/add-chequing-account.reducer';
import { CanNavigateBackActions } from '../../../../../../../../_shared/shared/store/reducers/can-navigate-back.reducer';

@Component({
  selector: 'add-chequing-account-confirmation',
  templateUrl: './add-chequing-account-confirmation.component.html',
  styleUrls: ['./add-chequing-account-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddChequingAccountConfirmationCanComponent extends BaseApplicationComponent<AddChequingAccountConfirmationCanComponent> implements OnInit, OnDestroy {
  contractDetail: ContractAccountDetailDTO;
  addAchPaymentSourceCanModel: AddAchPaymentSourceCanModel;
  bankNumber: string;
  bankTransitNumber: string;
  bankAccountNumber: string;
  bankAccountType: string;
  confirmationNumber: string;
  constructor(

    private routerService: RouterService,
    private canNavigateBackActions: CanNavigateBackActions,
    private logSiteActivityService: LogSiteActivityService,
    private windowService: WindowService,
    private addChequingAccountActions: AddChequingAccountActions,
    private maskService: MaskService,
    @Inject(DOCUMENT) document,
    injector: Injector) {
    super(injector, document);
  }

  ngOnInit() {
    this.dataLayer = {
      page: {
        name: `${Constants.MyAccount}:${Constants.AddChequingAccountConfirmationCanComponentName}`,
        pageType: Constants.PaymentDetails,
        pageLevel2: Constants.Accounts,
        title: Constants.AddChequingAccountConfirmationCanComponentName
      }
    }
    super.pushDataLayer();
    this.setDataLoadedOnRequest(false);
    this.store.select(state => state.ContractAccountDetail).subscribe(x => this.contractDetail = x);
    this.init();
    this.setDataLoadedOnRequest(true);

  }

  public init() {
    this.store.select(state => state.AddChequingAccount).subscribe(x => this.addAchPaymentSourceCanModel = x);
    this.bankNumber = this.addAchPaymentSourceCanModel.bankNumber;
    this.bankTransitNumber = this.addAchPaymentSourceCanModel.bankTransitNumber;
    this.bankAccountNumber = this.maskService.getAccountNoWithMaskedChar(this.addAchPaymentSourceCanModel.bankAccountNumber, Constants.MaskWithStar);
    this.bankAccountType = AccountTypes.Chequing;
    this.confirmationNumber = this.addAchPaymentSourceCanModel.confirmationNumber;
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(false));
    this.store.dispatch(this.addChequingAccountActions.clearAddChequingAccount());
  }

  public navigateToHome() {
    this.store.dispatch(this.canNavigateBackActions.setCanNavigateBack(true));
    this.routerService.navigateToHome();
  }
  ngOnDestroy() {
    this.store.dispatch(this.addChequingAccountActions.clearAddChequingAccount());
  }
  print(): void {
    this.windowService.print();
  }


  public isAllowToBackward(): boolean {
    let canNavigateBack: boolean;
    this.store.select(state => state.CanNavigateBack.booleanValue).subscribe(x => canNavigateBack = x);
    return (canNavigateBack) ? false : true;
  }
}
