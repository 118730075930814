import { ApplicationConfig } from './../../../_shared/_models/application-config';
import { BaseComponentModule } from './../../../_shared/core/basecomponent/base-component.module';
import { PaymentSourceCanModule } from './payment-source/payment-source.module.can';
import { routingBmwCan } from './app-routing';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common'
import { SharedModule } from './../../../_shared/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeatureToggleModule } from './../../../_shared/feature/feature-toggle/feature-toggle.module';
import { VehicleReturnEstimateModule } from './../../../_shared/feature/vehicle-return-estimate/vehicle-return-estimate.module';

//interceptors
import { BearerTokenInterceptor } from './../../../_shared/core/interceptors/bearer-token.interceptor';
import { AuthorizationInterceptor } from './../../../_shared/core/interceptors/authorization.interceptor';
import { ActivityIdInterceptor } from './../../../_shared/core/interceptors/activity-id.interceptor';

// State Management
import { Store, StoreModule } from '@ngrx/store';
import { IAppState, rootReducer, INITIAL_STATE } from './../../../_shared/shared/store/app.store';
import { HelpServiceActions } from './../../../_shared/shared/store/reducers/help.service.reducers';
import { DataLoadedActions } from './../../../_shared/shared/store/reducers/dataloaded.reducer';
import { UserIsTimedOutActions } from './../../../_shared/shared/store/reducers/user-is-timed-out.reducer';
import { IsInPaymentFlowActions } from './../../../_shared/shared/store/reducers/is-in-payment-flow.reducer';
import { IsInMAPFlowActions } from './../../../_shared/shared/store/reducers/is-in-map-flow.reducer';
import { CanNavigateBackActions } from './../../../_shared/shared/store/reducers/can-navigate-back.reducer';
import { EmailIdActions } from './../../../_shared/shared/store/reducers/email-id.reducer';
import { GlobalErrorActions } from './../../../_shared/shared/store/reducers/global-error-message.reducer';
import { ParentActivityIdActions } from './../../../_shared/shared/store/reducers/parent-activity-id.reducer';
import { UserActivityIdActions } from './../../../_shared/shared/store/reducers/user-activityId.reducer';
import { PaymentReviewDetailsActions } from './../../../_shared/shared/store/reducers/payment-review-details.reducer';
import { ContractAccountDetailsActions } from './../../../_shared/shared/store/reducers/contract-account-details.reducers';
import { UserTokenActions } from './../../../_shared/shared/store/reducers/smc-user-token.reducer';
import { PaymentEntryActions } from './../../../_shared/shared/store/reducers/payment-entry.reducer';
import { ApplicationConfigActions } from './../../../_shared/shared/store/reducers/application-config.reducer';
import { EnvironmentConfigActions } from './../../../_shared/shared/store/reducers/environment-config.reducer';
import { ActiveContractAccountDetailsActions } from './../../../_shared/shared/store/reducers/active-contract-account-details.reducers';
import { ScheduledItemsActions } from './../../../_shared/shared/store/reducers/scheduled-items.reducers';
import { PayoffsActions } from './../../../_shared/shared/store/reducers/payoffs.reducers';
import { AutopaysActions } from './../../../_shared/shared/store/reducers/autopays.reducers';
import { StatementCollectionActions } from './../../../_shared/shared/store/reducers/statement-collection.reducers';
import { FinancialProductsActions } from './../../../_shared/shared/store/reducers/financial-products.reducers';
import { ContactActions } from './../../../_shared/shared/store/reducers/contact.reducers';
import { ContractAccountDetailActions } from './../../../_shared/shared/store/reducers/contract-account-detail.reducers';
import { CanNavigateToConfirmationActions } from './../../../_shared/shared/store/reducers/can-navigate-to-confirmation-page.reducer';
import { PaymentSourceActions } from './../../../_shared/shared/store/reducers/payment-source.reducers';
import { RecurringPaymentEntryActions } from './../../../_shared/shared/store/reducers/recurring-payment.reducer';
import { DueDateChangeReviewActions } from './../../../_shared/shared/store/reducers/due-date-change-review.reducer';
import { DueDateChangeEntryActions } from './../../../_shared/shared/store/reducers/due-date-change-entry.reducer';
import { InitiationIdActions } from "./../../../_shared/shared/store/reducers/initiation-id.reducer";
import { CancelRecurringPaymentReviewActions } from './../../../_shared/shared/store/reducers/recurring-payment-cancel.reducer';
import { SubjectTopicsActions } from './../../../_shared/shared/store/reducers/subject-topics.reducers';
import { ViewThreadActions } from './../../../_shared/shared/store/reducers/smc-view-thread.reducers';
import { ToastActions } from './../../../_shared/shared/store/reducers/toast.reducer';
import { AccountActivitiesActions } from './../../../_shared/shared/store/reducers/account-activities.reducers';
import { MaturityBillingActions } from './../../../_shared/shared/store/reducers/maturity-billing.reducer';
import { VehicleImageActions } from './../../../_shared/shared/store/reducers/vehicle-image.reducers';
import { ConnectedAppSessionIsExpiredActions } from './../../../_shared/shared/store/reducers/connected-app-session-is-expired.reducer';
import { PreferencesActions } from './../../../_shared/shared/store/reducers/preferences.reducers';
import { CustomerBulletinActions } from './../../../_shared/shared/store/reducers/customer-bulletin.reducer';
import { EasypaySchedulesActions } from './../../../_shared/shared/store/reducers/easypay-schedules.reducers';
import { SortedActiveContractAccountDetailsActions } from './../../../_shared/shared/store/reducers/sorted-active-contract-account-details.reducers';
import { SortedTerminatedContractAccountDetailsActions } from './../../../_shared/shared/store/reducers/sorted-terminated-contract-account-details.reducers';
import { ContractsLoadingErrorActions } from './../../../_shared/shared/store/reducers/contracts-loading-error.reducer';
import { UserActionOnAntoenrollActions } from './../../../_shared/shared/store/reducers/can-start-dashboard-tour.reducers';
import { MapQuoteSavedActions } from './../../../_shared/shared/store/reducers/map-quote-saved.reducer';
import { MapQuotesActions } from './../../../_shared/shared/store/reducers/map-quotes.reducers';
import { MapPaymentReviewDetailsActions } from './../../../_shared/shared/store/reducers/map-payment-review-details.reducer';


import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IdleExpiry, NgIdleModule } from '@ng-idle/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

// components
import { AppComponent } from './app.component';
import { PathNotFoundBmwCanComponent } from './path-not-found/path-not-found-bmw-can.component';
import { CancelRecurringPayment } from '../../../_shared/feature/recurring-payment/_components/recurring-payment-cancellation/recurring-payment-cancellation.component';
import { ToggleRecurringPayment } from '../../../_shared/feature/recurring-payment/_components/recurring-payment-toggle/recurring-payment-toggle.component'
import { NotificationDialog } from '../../../_shared/feature/my-account/_components/notifications/_components/notification-dialog/notification-dialog.component';
import { DeletePaymentSource } from '../../../_shared/feature/my-profile/_components/manage-payment-sources/_components/delete-payment-source/delete-payment-source.component';
import { EditAddressDialogComponent } from '../../../_shared/feature/my-profile/_components/contact-information/_components/edit-address-dialog/edit-address-dialog.component';
import { TaxJurisdictionDialogComponent } from '../../../_shared/feature/my-profile/_components/contact-information/_components/tax-jurisdiction-dialog/tax-jurisdiction-dialog.component';
import { CancelPendingOneTimePayment } from '../../../_shared/feature/my-account/_components/payment-details/_components/pending-one-time-payments/cancel-pending-payment-dialog/cancel-pending-payment-dialog.component';
import { ConfirmCancelDueDateChangeDialog } from '../../../_shared/feature/due-date-change/_components/confirm-cancel-due-date-change-dialog/confirm-cancel-due-date-change-dialog.component';
import { DueDateChangeDialog } from '../../../_shared/feature/due-date-change/_components/due-date-change/due-date-change-dialog.component';
import { MileageAdjustmentEntryDialog } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/mileage-adjustment-entry-dialog.component';
import { CurrentMilesComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/current-miles/current-miles.component';
import { SelectMilesComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/select-miles/select-miles.component';
import { PreferredPaymentOptionComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/preferred-payment-option/preferred-payment-option.component';
import { EntryMilesComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/entry-miles/entry-miles.component';
import { ExceedTotalMilesComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/exceed-total-miles/exceed-total-miles.component';
import { CancelPurchaseMilesComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/cancel-purchase-miles/cancel-purchase-miles.component';
import { QuoteSavedComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/quote-saved/quote-saved.component';
import { MileageAdjustmentPaymentCancelDialog } from '../../../_shared/feature/mileage-adjustment/_components/shared/mileage-adjustment-payment-cancel-dialog/mileage-adjustment-payment-cancel.component';
import { ErrorModalComponent } from '../../../_shared/feature/mileage-adjustment/_components/mileage-adjustment-entry-dialog/_components/error-modal/error-modal.component';
import { LegalAgreementDialog } from '../../../_shared/shared/_components/dialogs/legal-agreement-dialog/legal-agreement-dialog.component';
import { ExtendLeaseContractDialog } from '../../../_shared/shared/_components/dialogs/extend-lease-contract-dialog/extend-lease-contract-dialog.component';
import { RecallDialog } from '../../../_shared/feature/dashboard/_components/account-activity/_components/recall-dialog/recall-dialog.component';
import { LegalAgreementTermsConditions } from '../../../_shared/shared/_components/dialogs/legal-agreement-dialog/_components/legal-agreement-terms-conditions/legal-agreement-terms-conditions.component';
import { Agreement } from '../../../_shared/shared/_components/dialogs/legal-agreement-dialog/_components/legal-agreement-terms-conditions/_components/agreement/agreement.component';
import { LegalAgreementPaperless } from '../../../_shared/shared/_components/dialogs/legal-agreement-dialog/_components/legal-agreement-paperless/legal-agreement-paperless.component';
import { LegalAgreementDone } from '../../../_shared/shared/_components/dialogs/legal-agreement-dialog/_components/legal-agreement-done/legal-agreement-done.component';
import { MfeRemoteComponent } from '../../../_shared/feature/mfe-remote/mfe-remote.component';
// providers
import { SelectivePreloadingStrategy } from './../../../_shared/core/selective-preloading-strategy';
import { GuardService } from "./../../../_shared/shared/_helper-services/guard.service";
import { UserService } from './../../../_shared/shared/_helper-services/user.service';
import { ScreenSizeHelperService } from './../../../_shared/shared/_helper-services/screen-size-helper.service';
import { AuthService } from './../../../_shared/_web-services/auth.service';
import { RecallService } from '../../../_shared/_web-services/recall.service';
import { ObjectService } from './../../../_shared/shared/_helper-services/object.service';
import { TooltipService } from './../../../_shared/shared/_helper-services/tooltip.service';
import { LogSiteActivityService } from './../../../_shared/_web-services/log-site-activity.service';
import { MaturityBillingService } from "./../../../_shared/_web-services/maturity-billing.service";
import { FeatureIndicatorService } from './../../../_shared/_web-services/feature-indicator.service';
import { CustomerFinancialAccountService } from './../../../_shared/_web-services/customer-financial-account.service';
import { PreferenceCenterService } from './../../../_shared/_web-services/preference-center.service';
import { PaymentEstimationService } from '../../../_shared/_web-services/payment-estimation.service';
import { PaymentDueDateService } from './../../../_shared/_web-services/payment-due-date.service';
import { CustomerDeviceService } from './../../../_shared/_web-services/customer-device.service';
import { CustomerBulletinService } from './../../../_shared/_web-services/customer-bulletin.service';
import { MidtermMileageAdjustmentService } from './../../../_shared/_web-services/midterm-mileage-adjustment.service';
import {
    PaymentServiceClient,
    CustomerACHServiceClient,
    PartnerContactServiceClient,
    ResourceValueServiceClient,
    AuthServiceClient,
    RecallServiceClient,
    CashAccountViewServiceClient,
    PaymentViewServiceClient,
    PreferenceCenterServiceClient,
    API_BASE_URL,
    LogSiteActivityServiceClient,
    ContractualPayoffDocumentServiceClient,
    CustomerInvoiceServiceClient,
    ClientLogWriterClient,
    CustomerFinancialAccountServiceClient,
    PaymentCardServiceClient,
    AccountServiceClient,
    MaturityBillingServiceClient,
    FeatureIndicatorServiceClient,
    PaymentDueDateServiceClient,
    ConnectedAppApiServiceClient,
    CustomerDeviceServiceClient,
    CustomerBulletinServiceClient,
    MidtermMileageAdjustmentServiceClient,
    LeaseExtensionServiceClient,
    PaymentEstimationServiceClient,
    VehicleImageServiceClient
} from './../../../_shared/core/gateway-api';
import {
    FSTokenServiceClient,
    FS_TOKEN_API_BASE_URL
} from './../../../_shared/core/fs-token-api';

import { IdleService } from './../../../_shared/shared/_helper-services/idle.service';
import { DialogService } from './../../../_shared/shared/_helper-services/dialog.service';
import { AnalyticsService } from './../../../_shared/shared/_helper-services/analytics.service';

import { OrdinalPipe } from './../../../_shared/_pipes/ordinal.pipe';
import { AccountNumberMaskPipe } from './../../../_shared/_pipes/account-number-mask.pipe';
//Global Error handler
import { GlobalErrorHandler, FSTokenErrorHandler } from "./../../../_shared/shared/_errorhandler/gobal-error-handler";
import { EnvironmentSpecificService } from "./configuration/environment-specific-service";

import { CosyURLEncoder } from './../../../_shared/core/cosy-url-encoder';
import { AccountService } from './../../../_shared/_web-services/account.service';
import { CustomerInvoiceService } from "./../../../_shared/_web-services/customer-invoice.service";
import { ContractualPayoffDocumentService } from './../../../_shared/_web-services/contractual-payoff-document.service';
import { ClientLogWriterService } from "./../../../_shared/_web-services/client-log-writer.service";
import { PartnerContactService } from './../../../_shared/_web-services/partner-contact.service';
import { CustomerACHService } from './../../../_shared/_web-services/customer-ach.service';
import { CashAccountViewService } from './../../../_shared/_web-services/cash-account-view.service';
import { UserActionActions } from "./../../../_shared/shared/store/reducers/user-action.reducer";
import { SMCPostCreateDisplayActions } from "./../../../_shared/shared/store/reducers/smc-post-create-display.reducer";
import { RedirectCanModule } from "./../../../_shared/feature/redirect/redirect-can.module";
import { SsoModule } from "./../../../_shared/feature/sso/sso.module";
import { DeepLinkParamActions } from "./../../../_shared/shared/store/reducers/deeplink-param.reducer";
import { CancelAddChequingAccountGuard } from "./../../../_shared/_guard/cancel-add-chequing-account.guard";
import { PreviousRouteService } from './../../../_shared/shared/_helper-services/previous-route.service';
import { AddACHPaymentSourceComponent } from '../../../_shared/shared/_components/dialogs/add-ach-payment-source/add-ach-payment-source.component';
import { LegalAgreementDialogFooter } from '../../../_shared/shared/_components/dialogs/legal-agreement-dialog/_components/legal-agreement-dialog-footer/legal-agreement-dialog-footer.component';
import { HowToRedeemCreditPointsDialog } from '../../../_shared/shared/_components/dialogs/how-to-redeem-credit-points-dialog/how-to-redeem-credit-points-dialog.component';
import { CommunicationPreferencesDialog } from '../../../_shared/shared/_components/dialogs/communication-preferences-dialog/communication-preferences-dialog.component';
import { PaymentModule } from '../../../_shared/feature/payment/payment.module';
import { RecurringPaymentConfirmationComponent } from '../../../_shared/feature/recurring-payment/_components/recurring-payment-confirmation/recurring-payment-confirmation.component';
import { DueDateChangeConfirmationComponent } from '../../../_shared/feature/due-date-change/_components/due-date-change-confirmation/due-date-change-confirmation.component';
import { FeatureIndicatorActions } from '../../../_shared/shared/store/reducers/feature-indicator.reducers';
import { LeaseExtReviewDetailsActions } from '../../../_shared/shared/store/reducers/leaseext-review-details.reducer';
import { LeaseExtensionStoreCheckGuard } from '../../../_shared/_guard/lease-extension-store-check.guard';
import { LeaseExtensionCancelGuard } from '../../../_shared/_guard/lease-payment-cancel.guard';
import { ExtendLeaseContractCanDialog } from '../../../_shared/shared/_components/dialogs/extend-lease-contract-canada-dialog/extend-lease-contract-can-dialog.component';
import { MicroFrontendHelperService } from '../../../_shared/shared/_helper-services/mfe-helper.service';
import { OfferActions } from './../../../_shared/shared/store/reducers/offers.reducers';
import { VehicleImageService } from '../../../_shared/_web-services/vehicle-image.service';

import localeEN_CA from '@angular/common/locales/en-CA';
import localeEN_CAExtra from '@angular/common/locales/extra/en-CA';
registerLocaleData(localeEN_CA, localeEN_CAExtra);
import localeFR_CA from '@angular/common/locales/fr-CA';
import localeFR_CAExtra from '@angular/common/locales/extra/fr-CA';
registerLocaleData(localeFR_CA, localeFR_CAExtra);
import localeFR from '@angular/common/locales/fr';
import localeFRExtra from '@angular/common/locales/extra/fr';
import { CustomerdigitalServiceLibModule, LibConfigurationProvider, LoadExternalFileService, SessionStorageExpiry, UrlBuilderService } from 'customerdigital-service-lib';
import { IsEpassInitializedActions } from '../../../_shared/shared/store/reducers/is-epaas-initialized.reducer';
import { CannotAuthenticateActions } from '../../../_shared/shared/store/reducers/cannot-authenticate.reducer';
import { EpaasErrorComponent } from '../../../_shared/shared/_components/epaas-error/epaas-error.component';
import { TermsModule } from './terms/terms.module';
import { LoggerHelperService } from 'projects/_shared/shared/_helper-services/logger-helper.service';
import { LanguageActions } from 'projects/_shared/shared/store/reducers/language.reducer';
import { LanguageService } from 'projects/_shared/shared/_helper-services/language.service';
import { RouterService } from 'projects/_shared/shared/_helper-services/router.service';
import { LibConfiguration } from 'projects/_shared/shared/_helper-services/lib-config.service';

registerLocaleData(localeFR, localeFRExtra);

@NgModule({
    declarations: [
        AppComponent,
        PathNotFoundBmwCanComponent,
        DeletePaymentSource,
        CancelRecurringPayment,
        ToggleRecurringPayment,
        AddACHPaymentSourceComponent,
        ConfirmCancelDueDateChangeDialog,
        NotificationDialog,
        EditAddressDialogComponent,
        TaxJurisdictionDialogComponent,
        CancelPendingOneTimePayment,
        DueDateChangeDialog,
        MileageAdjustmentEntryDialog,
        MileageAdjustmentPaymentCancelDialog,
        LegalAgreementDialog,
        ExtendLeaseContractDialog,
        ExtendLeaseContractCanDialog,
        HowToRedeemCreditPointsDialog,
        CommunicationPreferencesDialog,
        CurrentMilesComponent,
        SelectMilesComponent,
        PreferredPaymentOptionComponent,
        EntryMilesComponent,
        ExceedTotalMilesComponent,
        CancelPurchaseMilesComponent,
        QuoteSavedComponent,
        ErrorModalComponent,
        LegalAgreementTermsConditions,
        Agreement,
        LegalAgreementPaperless,
        LegalAgreementDone,
        MfeRemoteComponent,
        RecurringPaymentConfirmationComponent,
        OrdinalPipe,
        DueDateChangeConfirmationComponent,
        LegalAgreementDialogFooter,
        RecallDialog,
        EpaasErrorComponent
    ],
    imports: [
        routingBmwCan,
        MatStepperModule,
        MatListModule,
        MatIconModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        BrowserAnimationsModule,
        FeatureToggleModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTooltipModule,
        NgIdleModule.forRoot(),
        StoreModule.forRoot (rootReducer, {
            runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false
            }
          }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BaseComponentModule,
        RedirectCanModule,
        SsoModule,
        PaymentSourceCanModule,
        VehicleReturnEstimateModule,
        PaymentModule,
        TermsModule,
        CustomerdigitalServiceLibModule.forRoot({
            config: {
                provide: LibConfigurationProvider,
                useClass: LibConfiguration,
                deps: [Store]
            },
        }, RouterService, AuthService, null, LanguageService, GuardService, null),
    ],
    exports: [
        EpaasErrorComponent
    ],
    providers: [
        VehicleImageService,
        VehicleImageServiceClient,
        SelectivePreloadingStrategy,
        CosyURLEncoder,
        GuardService,
        CurrencyPipe,
        DecimalPipe,
        PaymentDueDateService,
        ResourceValueServiceClient,
        { provide: IdleExpiry, useClass: SessionStorageExpiry },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ActivityIdInterceptor,
            multi: true
        },
        LogSiteActivityService,
        PartnerContactServiceClient,
        CashAccountViewServiceClient,
        PaymentViewServiceClient,
        PaymentServiceClient,
        CustomerFinancialAccountServiceClient,
        PaymentEstimationServiceClient,
        CustomerACHServiceClient,
        ContractualPayoffDocumentService,
        ContractualPayoffDocumentServiceClient,
        LeaseExtensionServiceClient,
        AuthServiceClient,
        RecallServiceClient,
        FSTokenServiceClient,
        PaymentViewServiceClient,
        LogSiteActivityServiceClient,
        Title,
        IdleService,
        DialogService,
        TranslateService,
        FSTokenErrorHandler, {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        HelpServiceActions,
        OfferActions,
        DataLoadedActions,
        UserIsTimedOutActions,
        EasypaySchedulesActions,
        ConnectedAppSessionIsExpiredActions,
        PreferencesActions,
        IsInPaymentFlowActions,
        IsInMAPFlowActions,
        CanNavigateBackActions,
        EmailIdActions,
        GlobalErrorActions,
        ParentActivityIdActions,
        UserActivityIdActions,
        PaymentReviewDetailsActions,
        LeaseExtReviewDetailsActions,
        ContractAccountDetailsActions,
        ActiveContractAccountDetailsActions,
        ScheduledItemsActions,
        PayoffsActions,
        AutopaysActions,
        FinancialProductsActions,
        StatementCollectionActions,
        ContactActions,
        ContractAccountDetailActions,
        UserTokenActions,
        PaymentEntryActions,
        ApplicationConfigActions,
        EnvironmentConfigActions,
        CanNavigateToConfirmationActions,
        PaymentSourceActions,
        RecurringPaymentEntryActions,
        DueDateChangeReviewActions,
        DueDateChangeEntryActions,
        InitiationIdActions,
        CancelRecurringPaymentReviewActions,
        ViewThreadActions,
        SubjectTopicsActions,
        UserActionActions,
        ToastActions,
        AccountActivitiesActions,
        MaturityBillingActions,
        VehicleImageActions,
        SMCPostCreateDisplayActions,
        DeepLinkParamActions,
        CustomerBulletinActions,
        SortedActiveContractAccountDetailsActions,
        SortedTerminatedContractAccountDetailsActions,
        ContractsLoadingErrorActions,
        UserActionOnAntoenrollActions,
        MapQuoteSavedActions,
        MapQuotesActions,
        MapPaymentReviewDetailsActions,
        PreferenceCenterServiceClient,
        PreferenceCenterService,
        AnalyticsService,
        EnvironmentSpecificService,
        CustomerFinancialAccountService,
        FeatureIndicatorActions,
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitializerLoaderFactory,
            deps: [EnvironmentSpecificService, UserService, Store, LanguageService],
            multi: true
        },
        {
            provide: API_BASE_URL,
            useFactory: BaseUrlLoaderFactory,
            deps: [Store],
        },
        {
            provide: FS_TOKEN_API_BASE_URL,
            useFactory: BaseUrlLoaderFactory,
            deps: [Store],
        },
        AccountService, AccountServiceClient,
        PaymentEstimationService,
        CustomerInvoiceService, CustomerInvoiceServiceClient,
        ClientLogWriterService, ClientLogWriterClient,
        CustomerFinancialAccountServiceClient,
        PartnerContactService,
        CustomerACHService,
        CashAccountViewService,
        AuthService,
        RecallService,
        UserService,
        ScreenSizeHelperService,
        ObjectService,
        TooltipService,
        PaymentCardServiceClient,
        MaturityBillingService, MaturityBillingServiceClient,
        FeatureIndicatorService, FeatureIndicatorServiceClient,
        OrdinalPipe,
        AccountNumberMaskPipe,
        PaymentDueDateServiceClient,
        ConnectedAppApiServiceClient,
        CustomerDeviceService,
        CustomerDeviceServiceClient,
        CustomerBulletinService, CustomerBulletinServiceClient,
        MidtermMileageAdjustmentService, MidtermMileageAdjustmentServiceClient,
        MicroFrontendHelperService,
        LoadExternalFileService,
        IsEpassInitializedActions,
        CannotAuthenticateActions,
        LoggerHelperService,
        LanguageActions,
        LanguageService,
        UrlBuilderService,
        {
            provide: "RouterService",
            useFactory: (ServiceFactory),
            deps: [RouterService]
        },
        {
            provide: 'AuthService',
            useFactory: (ServiceFactory),
            deps: [AuthService]
        },
        {
            provide: 'LanguageService',
            useFactory: (ServiceFactory),
            deps: [LanguageService]
        },
        {
            provide: 'GuardService',
            useFactory: (ServiceFactory),
            deps: [GuardService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private translate: TranslateService, private previousRouteService: PreviousRouteService, private languageService: LanguageService) {
        translate.addLangs(["ca-bmw-ngd-en", "ca-bmw-ngd-fr"]);
        translate.setDefaultLang('ca-bmw-ngd-en');
    }
}

export function HttpLoaderFactory(http: HttpClient) {

    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function AppInitializerLoaderFactory(environmentSpecificService: EnvironmentSpecificService, userService: UserService, store: Store<IAppState>, languageService: LanguageService) {

    //store.configureStore(rootReducer, INITIAL_STATE);
    var appConfig: ApplicationConfig = environmentSpecificService.loadApplicationConfig();
    userService.setApplicationConfig(appConfig);
    languageService.setLanguageFromQuery();
    return () => environmentSpecificService.loadEnvironment()
        .subscribe(e => {
            userService.setEnvironmentConfig(e);
        }
        );
}

export function BaseUrlLoaderFactory(store: Store<IAppState>) {
    let gatewayApiBaseUrl: string | undefined;
    store.select(state => state.EnvironmentConfig?.GATEWAY_API_BASE_URL).subscribe(x => gatewayApiBaseUrl = x)
    return gatewayApiBaseUrl;
}

export function ServiceFactory(injectedService: any) {
    return injectedService;
}