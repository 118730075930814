import { DOCUMENT } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseApplicationComponent } from '../../../../../../../../_shared/core/basecomponent/base.component';
import { ContractAccountDetailDTO } from '../../../../../../../../_shared/core/gateway-api';
import { Constants } from '../../../../../../../../_shared/shared/constants';

@Component({
  selector: 'app-download-pad-form',
  templateUrl: './download-pad-form.component.can.html',
  styleUrls: ['./download-pad-form.component.can.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadPadFormCanComponent extends BaseApplicationComponent<DownloadPadFormCanComponent> implements OnInit {

  contractDetail: ContractAccountDetailDTO;
  downloadPadFormTitle: string;
  downloadPadFormDescription: string;
  downloadPadFormLink: string;
  downloadPadFormPath: string;
  downloadPadFormText: string;

  constructor(
    private translateService: TranslateService,
    injector: Injector,
    @Inject(DOCUMENT) private _document
  ) {
    super(injector, _document);
  }

  ngOnInit() {
    let brandName: string;
    this.store.select(state => state.ApplicationConfig.BRAND).subscribe(x => brandName = x);

    this.translateService.get("ngw.add-chequing-account.download-pad-form.title", { Brand: brandName }).subscribe((res: string) => {
      this.downloadPadFormTitle = res;
    });
    this.translateService.get("ngw.add-chequing-account.download-pad-form.description", { Brand: brandName }).subscribe((res: string) => {
      this.downloadPadFormDescription = res;
    });
    this.translateService.get("ngw.add-chequing-account.download-pad-form.download-pad-form-link", { Brand: brandName }).subscribe((res: string) => {
      this.downloadPadFormLink = res;
    });
    this.translateService.get("ngw.add-chequing-account.download-pad-form.download-pad-form-text", { Brand: brandName }).subscribe((res: string) => {
      this.downloadPadFormText = res;
      this.downloadPadFormPath = `${Constants.PdfPath}${this.downloadPadFormText}${Constants.FileDownloadExtPdf}`;
    });
  }
}
