import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { ObjectService } from '../../../../../../../../_shared/shared/_helper-services/object.service';
import { RouterService } from '../../../../../../../../_shared/shared/_helper-services/router.service';
import { IAppState } from "../../../../../../../../_shared/shared/store/app.store";
import { AddAchPaymentSourceCanModel } from 'projects/_shared/_models/add-ach-payment-source-can.model';

export const AddChequingAccountConfirmationCanResolve: ResolveFn<any> = () => {
  const store = inject(Store<IAppState>);
  const routerService = inject(RouterService);
  const objectService = inject(ObjectService);
  let addChequingAccount: AddAchPaymentSourceCanModel;
  store.select(state => state.AddChequingAccount).subscribe(x => addChequingAccount = x);
  if (objectService.isEmptyObject(addChequingAccount) || !addChequingAccount.isValidBankAccountNumber) {
    routerService.navigateToHome();
    return;
  }
}

