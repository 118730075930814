import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../../../_shared/shared/_helper-services/router.service';
import { BaseDataLayerModel } from '../../../../_shared/_models/analytics/analytics-data.model';
import { Constants } from '../../../../_shared/shared/constants';
import { AnalyticsService } from '../../../../_shared/shared/_helper-services/analytics.service';

@Component({
  selector: 'app-path-not-found-bmw-can',
  templateUrl: './path-not-found-bmw-can.component.html',
  styleUrls: ['./path-not-found-bmw-can.component.scss']
})
export class PathNotFoundBmwCanComponent implements OnInit {

  constructor(private routerService: RouterService, private analyticsService: AnalyticsService) { }

  ngOnInit() {
    let dataLayer: Partial<BaseDataLayerModel> = {
      page: {
        name:`${Constants.MyAccount}:${Constants.PageNotFoundComponentName}:${Constants.page404}`,
        pageType: Constants.ErrorPage,
        pageLevel2: Constants.page404,
        title: Constants.PageNotFoundComponentName
       }
    }
    this.analyticsService.pushDataLayer({ dataLayer });
  }

  navigateToBaseURL(): void {
    this.routerService.navigateToHome();
  }

}
