import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'projects/_shared/_guard/auth.guard';
import { NotTermsGuard } from 'projects/_shared/_guard/not-terms-accepted';
import { TermsAcceptComponent } from './terms-accept/terms-accept.component';
import { TermsViewComponent } from './terms-view/terms-view.component';

const termsRoutes: Routes = [
    { path: 'view', component: TermsViewComponent },
    { path: 'accept', component: TermsAcceptComponent, canActivate: [NotTermsGuard, AuthGuard] }
];

@NgModule({
    imports: [
        RouterModule.forChild(termsRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class TermsRoutingModule { }