import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../../_shared/shared/shared.module';
import { AddChequingAccountConfirmationCanComponent } from './_components/add-ach-payment-source-can/_components/add-chequing-account-confirmation/add-chequing-account-confirmation.component';
import { AddChequingAccountConfirmationCanResolve } from './_components/add-ach-payment-source-can/_components/add-chequing-account-confirmation/add-chequing-account-confirmation.resolve';
import { AddChequingAccountDetailsCanComponent } from './_components/add-ach-payment-source-can/_components/add-chequing-account-details/add-chequing-account-details.component.can';
import { DownloadPadFormCanComponent } from './_components/add-ach-payment-source-can/_components/download-pad-form/download-pad-form.component.can';
import { UploadChequeAndPadComponent } from './_components/add-ach-payment-source-can/_components/upload-cheque-pad-form/upload-cheque-pad-form.component.can';
import { AddACHPaymentSourceCanComponent } from './_components/add-ach-payment-source-can/add-ach-payment-source.component.can';
import { PaymentSourceRoutingCanModule } from './payment-source-routing.module.can';

@NgModule({
    imports: [
        CommonModule,  
        SharedModule,
        TranslateModule,
        PaymentSourceRoutingCanModule
    ],
  declarations: [
    AddACHPaymentSourceCanComponent,
    DownloadPadFormCanComponent,
    UploadChequeAndPadComponent,
    AddChequingAccountDetailsCanComponent,
    AddChequingAccountConfirmationCanComponent
    
  ],
})
export class PaymentSourceCanModule { }
