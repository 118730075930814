<footer class="no-print">
  <nav>
    <div class="desktop-container">
      <span class="copyright">&copy;  {{currentDate | date:'y'}} {{"ngw.footer.trademark-text" | translate}}</span>
      <ul>
        <li id="item.id" *ngFor="let item of footerMenuList;let i=index" [class.active]="isActive(i)">
          <a (click)="this[item.eventName](i)" target="_blank">{{item.innerText | translate}}</a>
        </li>
      </ul>
    </div>
  </nav>
</footer>
