import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AuthService } from 'projects/_shared/_web-services/auth.service';
import { LanguageService } from 'projects/_shared/shared/_helper-services/language.service';
import { LoggerHelperService } from 'projects/_shared/shared/_helper-services/logger-helper.service';
import { RouterService, route } from 'projects/_shared/shared/_helper-services/router.service';
import { UserService } from 'projects/_shared/shared/_helper-services/user.service';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terms-accept',
  templateUrl: "./terms-accept.component.html",
  styleUrls: ['./terms-accept.component.scss']
})

export class TermsAcceptComponent implements OnDestroy {

  termsContentHtml !: SafeHtml;
  termsLoadFailed = false;
  private langChangeSubscription: any;
  dataLoaded!: boolean;
  dataLoadedSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private routerService: RouterService,
    private sanitizer: DomSanitizer,
    private loggerHelperService: LoggerHelperService,
    private languageService: LanguageService,
    private store: Store<IAppState>
  ) {
    this.dataLoadedSubscription = this.store.select(state => state.DataLoaded.booleanValue)
      .subscribe(loaded => this.dataLoaded = loaded);

    this.langChangeSubscription = this.store.select(state => state.Language.stringValue).subscribe(() => {
      this.getTermsHtml();
    });
  }

  private getTermsHtml() {
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
    this.dataLoadedSubscription.unsubscribe();
  }

  displayTermsCondition(success: boolean, terms: string) {
    if (success) {
      this.termsContentHtml = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    this.termsLoadFailed = !success;
  }

  cancelTerms() {
    this.routerService.navigateToLogout();
  }

  acceptTerms() {
    let gcid = this.userService.getGcid();
    this.authService.acceptTermsAndConditions(gcid)
      .subscribe(response => { this.postAcceptTerms(response); });
  }

  private postAcceptTerms(response: boolean) {
    if (response) {
      this.userService.setTermsAccepted(response);
      this.routerService.navigateToBaseUrl();
    } else {
      this.loggerHelperService.logCritical(`Error occurred in TermsAcceptComponent, Gcid: ${this.userService.getGcid()} , authService.acceptTermsAndConditions().`);
      this.cancelTerms();
    }
  }

  termsView() {
    this.routerService.navigateToNewTabWithParam(route.termsViewLink, this.languageService.getLanguageParameter());
  }

}