import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AuthService } from 'projects/_shared/_web-services/auth.service';
import { LanguageService } from 'projects/_shared/shared/_helper-services/language.service';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terms-view',
  templateUrl: "./terms-view.component.html",
  styleUrls: ['./terms-view.component.scss']
})

export class TermsViewComponent implements OnDestroy {
  termsLoadFailed = false;
  htmlContent !: SafeHtml;
  langChangeSubscription: any;
  dataLoadedSubscription: Subscription;
  dataLoaded!: boolean;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private languageService: LanguageService,
    private store: Store<IAppState>
  ) {
    this.dataLoadedSubscription = this.store.select(state => state.DataLoaded.booleanValue)
      .subscribe(loaded => this.dataLoaded = loaded);
    
    this.langChangeSubscription = this.store.select(state => state.Language.stringValue).subscribe(() => {
      this.getTermsHtml();
    }
    );
  }

  private getTermsHtml() {
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });
  }

  closeTerms() {
    window.close();
  }

  displayTermsCondition(success: boolean, terms: string) {
    if (success) {
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    this.termsLoadFailed = !success;
  }

  ngOnDestroy(): void {
    this.dataLoadedSubscription.unsubscribe();
    this.langChangeSubscription.unsubscribe();
  }
}