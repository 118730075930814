import { RouterModule, Routes } from '@angular/router';
import { route } from '../../../_shared/shared/_helper-services/router.service';
import { PathNotFoundBmwCanComponent } from './path-not-found/path-not-found-bmw-can.component';
import { SortedFilteredContractsResolve } from 'projects/_shared/_resolvers/sorted-filtered-contracts.resolve';
import { EndOfTermFeatureIndicatorResolve } from 'projects/_shared/_resolvers/end-of-term-indicator.resolve';
import { Constants } from 'projects/_shared/shared/constants';

const appBmwCanRoutes: Routes = [
  { path: route.empty, pathMatch: 'full', redirectTo: route.home },
  { path: route.home, loadChildren: () => import('./dashboard/dashboard.module.can').then(m => m.DashboardCanModule), data: { preload: true } },
  { path: route.navigation, loadChildren: () => import('../../../_shared/feature/navigation/navigation-can.module').then(m => m.NavigationCanModule), data: { preload: true } },
  { path: route.login, loadChildren: () => import('../../../_shared/feature/user-management/login/login.module').then(m => m.LoginModule), data: { preload: true } },
  { path: route.sso, pathMatch: 'full', redirectTo: 'sso' },
  { path: route.legitimization, loadChildren: () => import('./user-management/legitimization/legitimization.module.can').then(m => m.LegitimizationCanModule) },
  { path: route.twoFactorAuth, loadChildren: () => import('./user-management/authentication/authentication.module.can').then(m => m.AuthenticationCanModule) },
  { path: route.accounts, loadChildren: () => import('./home/home.module.can').then(m => m.HomeCanModule) },
  { path: route.error, loadChildren: () => import('../../../_shared/shared/_components/error/error.module').then(m => m.ErrorModule) },

  { path: route.redirect, pathMatch: 'full', redirectTo: 'redirect' },
  { path: route.contactus, loadChildren: () => import('../../../_shared/feature/contact-us/contact-us.module').then(m => m.ContactUsModule), data: { subNavType: Constants.Empty }, resolve: { SortedFilteredContractsResolve, EndOfTermFeatureIndicatorResolve } },
  { path: route.myaccount, loadChildren: () => import('./my-account/my-account.module.can').then(m => m.MyAccountCanModule), data: { preload: true } },
  { path: route.offers, loadChildren: () => import('../app/offers/offers.module.can').then(m => m.OffersModuleCan) },
  { path: route.faq, loadChildren: () => import('../../../_shared/feature/faq/faq.module').then(m => m.FAQModule), data: { subNavType: Constants.Empty }, resolve: { SortedFilteredContractsResolve, EndOfTermFeatureIndicatorResolve } },
  { path: route.myProfile, loadChildren: () => import('./my-profile/my-profile.module.can').then(m => m.MyProfileCanModule) },
  { path: 'securemessagecenter', loadChildren: () => import('./secure-message-center/secure-msg-center.module.can').then(m => m.SecureMessageCenterCanModule) },
  { path: 'payment', loadChildren: () => import('./payment/payment.module.can').then(m => m.PaymentCanModule), data: { preload: true } },
  { path: 'reschedule', loadChildren: () => import('./payment-reschedule-can/payment-reschedule.module').then(m => m.PaymentRescheduleModule), data: { preload: true } },
  { path: 'extension', loadChildren: () => import('./lease-extension/lease-extension.module').then(m => m.LeaseExtensionModule), data: { preload: true } },
  { path: route.cookiePolicy, loadChildren: () => import('../../../_shared/feature/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule), data: { subNavType: Constants.Empty }, resolve: { SortedFilteredContractsResolve, EndOfTermFeatureIndicatorResolve } },
  { path: route.terms, loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule) },
  { path: '**', pathMatch: 'full', component: PathNotFoundBmwCanComponent }
];

export const routingBmwCan = RouterModule.forRoot(appBmwCanRoutes, { scrollPositionRestoration: 'top' });
